type Endpoint =
  | 'title'
  | 'open_research_sources'
  | 'tag'
  | 'open_research'
  | 'random_questions'
  | 'suggested_questions';

interface StreamAnswerRequest {
  question_id: string;
  question: string;
  previous_questions: string[];
  source_list: string[];
  with_upload: boolean;
}

interface RandomQuestionRequest {
  tag: string;
  profession: string;
  previous_questions: string[];
  num_questions: number;
  language: string;
}

interface SuggestedQuestionsRequest {
  query_id: string;
  previous_questions: string[];
  num_questions: number;
}

interface MakeRequestReturn {
  response: Response;
  abortController: AbortController;
}

interface StreamPostReturn {
  abort: () => void;
}

export interface Source {
  url: string;
  title: string;
}

export class QnaAPI {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private async makeRequest<T>(
    endpoint: Endpoint,
    data: T,
  ): Promise<MakeRequestReturn> {
    const abortController = new AbortController();
    const response = await fetch(`${this.baseUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data }),
      signal: abortController.signal,
    });
    return { response, abortController };
  }

  private async streamPost<T>(
    endpoint: Endpoint,
    data: T,
    chunkCb: (chunk: string) => void,
    endCb?: (content: string) => void,
  ): Promise<StreamPostReturn> {
    const { response, abortController } = await this.makeRequest(
      endpoint,
      data,
    );
    if (!response.body) {
      throw new Error('No response from the server');
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    (async () => {
      let done, value;
      let content = '';
      while (!done) {
        ({ value, done } = await reader.read());
        const chunk = decoder.decode(value);
        content += chunk;
        chunkCb(chunk);
      }
      if (endCb) endCb(content);
    })();
    return {
      abort: () => {
        reader.cancel();
        abortController.abort();
      },
    };
  }

  private async jsonPost<T>(endpoint: Endpoint, data: T) {
    const { response } = await this.makeRequest(endpoint, data);
    const result = await response.json();
    return result;
  }

  public async fetchTitle(query: string): Promise<string> {
    const result = await this.jsonPost('title', { query });
    return result.data.title;
  }

  public async fetchSources(
    question: string,
    question_id: string,
  ): Promise<Source[]> {
    const result = await this.jsonPost('open_research_sources', {
      question,
      question_id,
    });
    return result.data.sources;
  }

  public async fetchTag(
    question: string,
    previous_tags: string[] = [],
  ): Promise<string[]> {
    const result = await this.jsonPost('tag', { question, previous_tags });
    return result.tags;
  }

  public async streamAnswer(
    data: StreamAnswerRequest,
    chunkCb: (chunk: string) => void,
    endCb?: (content: string) => void,
  ): Promise<() => void> {
    const { abort } = await this.streamPost(
      'open_research',
      data,
      chunkCb,
      endCb,
    );
    return abort;
  }

  public async fetchRandomQuestions(
    data: RandomQuestionRequest,
  ): Promise<string[]> {
    const result = await this.jsonPost('random_questions', data);
    return result.questions;
  }

  public async fetchSuggestedQuestions(
    data: SuggestedQuestionsRequest,
  ): Promise<string[]> {
    const result = await this.jsonPost('suggested_questions', data);
    return result.questions;
  }
}

if (!process.env.NEXT_PUBLIC_QUERY_API_BASE_URL) {
  throw new Error('NEXT_PUBLIC_QUERY_API_BASE_URL env not set');
}

export const qnaAPI = new QnaAPI(process.env.NEXT_PUBLIC_QUERY_API_BASE_URL);
