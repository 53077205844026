import useAuth from 'hooks/useAuth';
import { ServerTypes } from 'lib/types';
import { qnaAPI } from './qnaAPI';
import { Question } from './question';
import { StateItem, useStateItem } from './stateItem';
import { analytics } from 'lib/analytics';

const discoveryTexts = [
  'اكتشف',
  'انقر هنا للمزيد من المعلومات',
  'سؤال عشوائي',
  'انقر مرة أخرى',
  'رائج الآن',
  'سؤال جديد',
  '  محتوى مخصص',
];

export class Discovery {
  constructor(
    private user: Partial<ServerTypes.UserWithProfessions> | null,
    private tick: StateItem<number>,
    private question: Question,
    private questions: StateItem<string[]>,
    private textIdx: StateItem<number>,
  ) {}

  public perform(): void {
    if (this.questions.get().length === 0) return;
    const questionIdx = this.questionIdx();
    const question = this.questions.get()[questionIdx];

    analytics.clickedDiscover(this.user, question);
    this.question.create(question);
    if (this.questions.get().length <= 5) this.fetch();
    this.questions.set((old) => [
      ...old.slice(0, questionIdx),
      ...old.slice(questionIdx + 1),
    ]);
    this.incrementTextIdx();
  }

  private appendDisoveryQuestions(questions: string[]): void {
    this.questions.set((old) => [...old, ...questions]);
  }

  async fetch() {
    if (this.questions.get().length >= 5) return;
    const questions = await qnaAPI.fetchRandomQuestions({
      num_questions: 10,
      previous_questions: await this.question.previousQuestions(10),
      profession: this.user?.professions?.[0]?.name || '',
      tag: '',
      language: 'AR',
    });
    this.appendDisoveryQuestions(questions);
  }

  private questionIdx(): number {
    return this.tick.get() % this.numQuestions();
  }

  private numQuestions(): number {
    return this.questions.get().length;
  }

  private hasQuestions(): boolean {
    return this.numQuestions() > 0;
  }

  public isDisabled(): boolean {
    return !this.hasQuestions() || this.question.getIsStreaming();
  }

  private incrementTextIdx() {
    this.textIdx.set((x) => (x + 1) % discoveryTexts.length);
  }

  public getButtonText(): string {
    const textIdx = this.textIdx.get();
    return discoveryTexts[textIdx];
  }

  public getTooltip(): string {
    if (!this.hasQuestions()) return '';
    const questions = this.questions.get();
    const questionIdx = this.questionIdx();
    return questions[questionIdx];
  }
}

export function useDiscovery(
  question: Question,
  tick: StateItem<number>,
): Discovery {
  const [user] = useAuth();
  const discovery = new Discovery(
    user,
    tick,
    question,
    useStateItem([]),
    useStateItem(0),
  );
  return discovery;
}
