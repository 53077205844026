import useAuth from 'hooks/useAuth';
import { Discovery, useDiscovery } from 'providers/discovery';
import { Question, useQuestion } from 'providers/question';
import { SavedSearches, useSavedSearches } from 'providers/savedSearches';
import { SearchForm, useSearchForm } from 'providers/searchForm';
import { Share, useShare } from 'providers/share';
import {
  SourceManagement,
  useSourceManagement,
} from 'providers/sourceManagement';
import { ServerTypes } from 'lib/types';
import { StateItem } from 'providers/stateItem';

export class SearchCompanion {
  constructor(
    private user: Partial<ServerTypes.UserWithProfessions> | null,
    private queryId: string,
    public discovery: Discovery,
    public question: Question,
    public savedSearches: SavedSearches,
    public searchForm: SearchForm,
    public share: Share,
    public sourceManagement: SourceManagement,
  ) {}

  public onRouteChange() {
    if (this.queryId) this.question.fetchFromDb();
    if (!this.user) return;
    this.savedSearches.fetch();
    this.discovery.fetch();
  }
}

export function useSearchCompanion(
  queryId: string,
  tick: StateItem<number>,
): SearchCompanion {
  const savedSearches = useSavedSearches();
  const question = useQuestion(savedSearches);
  const [user] = useAuth();
  return new SearchCompanion(
    user,
    queryId,
    useDiscovery(question, tick),
    question,
    savedSearches,
    useSearchForm(question),
    useShare(question),
    useSourceManagement(question),
  );
}
