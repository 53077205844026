import {
  useState,
  SetStateAction,
  Dispatch,
} from 'react';

export class StateItem<T> {
  private value: T;
  public readonly set: Dispatch<SetStateAction<T>>;

  constructor(state: [T, Dispatch<SetStateAction<T>>]) {
    [this.value, this.set] = state;
  }

  public get(): T {
    return this.value;
  }
}

export function useStateItem<T>(initial: T): StateItem<T> {
  return new StateItem(useState<T>(initial));
}
