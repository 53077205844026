'use client';

let already_running = false; // avoid double render issue

import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import { useParams } from 'next/navigation';
import { SearchCompanion, useSearchCompanion } from './searchCompanion';
import { useStateItem } from './stateItem';

const SearchContext = createContext<SearchCompanion | null>(null);

export function SearchProvider({ children }: { children: ReactNode }) {
  const { queryId } = useParams<{ queryId: string }>();
  const tick = useStateItem(0); // ticks every 5 seconds and allows components to update
  const searchCompanion = useSearchCompanion(queryId, tick);
  useEffect(() => {
    // runs whenever the route changes
    const intervalId = setInterval(() => tick.set(x => x + 1), 5000);
    if (already_running) return; // don't re-run too quickly (e.g. double run on start up)
    already_running = true;
    setTimeout(() => {
      already_running = false;
    }, 100);
    searchCompanion.onRouteChange();
    return () => clearInterval(intervalId);
  }, [queryId]);
  return (
    <SearchContext.Provider value={searchCompanion}>
      {children}
    </SearchContext.Provider>
  );
}

export { SearchContext };

export function useSearch() {
  return useContext(SearchContext);
}
