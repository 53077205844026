import { trpc } from 'lib/trpc';
import { TagSearch } from 'lib/types';
import { StateItem, useStateItem } from './stateItem';
import { makeCompare } from './makeCompare';


export class SavedSearches {
  constructor(private searches: StateItem<TagSearch[]>) {}

  public async fetch() {
    const results = await trpc.search.getTaggedSearches.query();
    const taggedSearches: TagSearch[] = [];
    for (const tag of Object.keys(results)) {
      const tagSearches = results[tag].filter((search) => search.title.length);
      tagSearches.sort(makeCompare('title'));
      if (tagSearches.length)
        taggedSearches.push({ tag, searches: tagSearches });
      taggedSearches.sort(makeCompare('tag'));
    }
    this.searches.set(taggedSearches);
  }

  public get(): TagSearch[] {
    return this.searches.get();
  }
}

export function useSavedSearches(): SavedSearches {
  return new SavedSearches(useStateItem([]));
}
