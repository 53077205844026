import { ChangeEvent, FormEvent } from 'react';
import { Question } from './question';
import { StateItem, useStateItem } from './stateItem';

export class SearchForm {
  constructor(
    private question: Question,
    private searchText: StateItem<string>,
  ) {}

  public getSearchText(): string {
    return this.searchText.get();
  }

  public onTextChanged(e: ChangeEvent<HTMLInputElement>): void {
    const text = e.target.value;
    this.searchText.set(text);
  }

  public async onSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    const question = this.searchText.get();
    await this.question.create(question);
    this.searchText.set('');
  }
}

export function useSearchForm(question: Question) {
  return new SearchForm(question, useStateItem(''));
}
