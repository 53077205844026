import { Question } from './question';
import { StateItem, useStateItem } from './stateItem';

export class SourceManagement {
  constructor(
    private question: Question,
    private isOpen: StateItem<boolean>,
  ) {}

  public getIsOpen(): boolean {
    return this.isOpen.get();
  }

  public open(): void {
    this.isOpen.set(true);
  }

  public close(): void {
    this.isOpen.set(false);
  }

  public regenerate(): void {
    this.question.regenerate();
    this.close();
  }
}

export function useSourceManagement(question: Question): SourceManagement {
  return new SourceManagement(question, useStateItem(false));
}
