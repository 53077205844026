import { v4 as uuid } from 'uuid';

import useAuth from 'hooks/useAuth';
import { trpc } from 'lib/trpc';
import { ServerTypes } from 'lib/types';
import { useParams, useRouter } from 'next/navigation';
import { Source, qnaAPI } from './qnaAPI';
import { RefItem, useRefItem } from './refItem';
import { StateItem, useStateItem } from './stateItem';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { SavedSearches } from './savedSearches';
import { makeCompare } from './makeCompare';
import { analytics } from 'lib/analytics';

export interface SourceWithState extends Source {
  isDeleted: boolean;
}

export interface SourceTitleWithState {
  title: string;
  isDeleted: boolean;
  url: string;
}

export class Question {
  constructor(
    private queryId: string,
    private user: Partial<ServerTypes.UserWithProfessions> | null,
    private router: AppRouterInstance,
    private text: StateItem<string>,
    private title: StateItem<string>,
    private answer: StateItem<string>,
    private sources: StateItem<Source[]>,
    private deletedSources: StateItem<Set<string>>,
    private tags: StateItem<string[]>,
    private isStreaming: StateItem<boolean>,
    private abort: RefItem<(() => void) | null>,
    private savedSearches: SavedSearches,
  ) {}

  public getQueryId(): string {
    const queryId = this.queryId;
    if (!queryId) throw new Error('Query id not set');
    return queryId;
  }

  public getText(): string {
    const text = this.text.get();
    return text;
  }

  public safeGetText(): string {
    const text = this.getText();
    if (!text) throw new Error('Text is not set');
    return text;
  }

  public getTitle(): string {
    return this.title.get();
  }

  private async fetchTitle(question: string): Promise<void> {
    const queryId = this.getQueryId();
    const title = await qnaAPI.fetchTitle(question);
    this.title.set(title);
    if (!this.user) return;
    await trpc.search.setTitle.mutate({ queryId, title });
    this.savedSearches.fetch();
  }

  public getAnswer(): string {
    return this.answer.get();
  }

  public hasAnswer(): boolean {
    return this.answer.get().length > 0;
  }

  private appendAnswer(text: string): void {
    this.answer.set((oldAnswer) => oldAnswer + text);
  }

  private async streamAnswer(question: string): Promise<void> {
    const queryId = this.getQueryId();
    this.answer.set('');
    this.isStreaming.set(true);
    const abort = await qnaAPI.streamAnswer(
      {
        previous_questions: await this.previousQuestions(),
        question,
        question_id: queryId,
        source_list: Array.from(this.deletedSources.get()),
        with_upload: false,
      },
      (chunk) => this.appendAnswer(chunk),
      (content) => {
        this.isStreaming.set(false);
        if (!this.user) return;
        trpc.search.setAnswer.mutate({ queryId, answer: content });
        analytics.questionAnswered(
          this.user,
          question,
          queryId,
          this.getTags(),
          this.getSources(),
          content,
        );
        // fetch follow up questions
      },
    );
    this.abort.set(abort);
  }

  public getIsStreaming(): boolean {
    return this.isStreaming.get();
  }

  public getSources(): SourceWithState[] {
    const result: SourceWithState[] = [];
    const deletedSources = this.deletedSources.get();
    for (const source of this.sources.get()) {
      const sourceWithState: SourceWithState = {
        isDeleted: deletedSources.has(source.url),
        title: source.title,
        url: source.url,
      };
      result.push(sourceWithState);
    }
    result.sort(makeCompare('title'));
    return result;
  }

  public getSourceTitles(): SourceTitleWithState[] {
    const processedTitles = new Set<string>();
    const deletedSources = this.deletedSources.get();
    const result: SourceTitleWithState[] = [];
    for (const source of this.sources.get()) {
      result.push({
        isDeleted: deletedSources.has(source.url),
        title: source.title,
        url: source.url,
      });
      processedTitles.add(source.title);
    }
    result.sort(makeCompare('title'));
    return result;
  }

  public toggleSourceTitle(title: string): void {
    const deletedSources = this.deletedSources.get();
    for (const source of this.sources.get()) {
      if (source.title !== title) continue;
      const isDeleted = deletedSources.has(source.url);
      if (isDeleted) {
        deletedSources.delete(source.url);
        analytics.reenabledSource(
          this.user,
          this.queryId,
          this.getText(),
          source.title,
          source.url,
        );
      } else {
        deletedSources.add(source.url);
        analytics.deletedSource(
          this.user,
          this.queryId,
          this.getText(),
          source.title,
          source.url,
        );
      }
    }
    this.deletedSources.set(new Set(deletedSources));
  }

  public hasDeletedSources(): boolean {
    const deletedSources = this.deletedSources.get();
    return deletedSources.size > 0;
  }

  private async fetchSources(question: string): Promise<void> {
    const queryId = this.getQueryId();
    const sources = await qnaAPI.fetchSources(question, queryId);
    this.sources.set(sources);
    if (!this.user) return;
    trpc.search.setSources.mutate({ queryId, sources });
  }

  public getTags(): string[] {
    return this.tags.get();
  }

  private async fetchTags(question: string): Promise<void> {
    const queryId = this.getQueryId();
    const tags = await qnaAPI.fetchTag(question);
    this.tags.set(tags);
    if (!this.user) return;
    await trpc.search.setTags.mutate({ queryId, tags });
    this.savedSearches.fetch();
  }

  private clear(): void {
    this.stopStreaming();
    this.queryId = '';
    this.text.set('');
    this.title.set('');
    this.answer.set('');
    this.sources.set([]);
    this.deletedSources.set(new Set());
    this.tags.set([]);
    this.isStreaming.set(false);
  }

  public async create(question: string): Promise<void> {
    this.clear();
    const queryId = uuid();
    await trpc.search.create.mutate({ question, queryId });
    this.router.push(`/search/${queryId}`);
  }

  async previousQuestions(num = 5): Promise<string[]> {
    let previousQuestions;
    if (this.user?.authUserId) {
      previousQuestions = await trpc.chat.getLastNQuestionsByUser.query({
        numberOfQuestionsToGet: num,
        authUserId: this.user?.authUserId,
      });
    }
    return previousQuestions
      ? previousQuestions.map((el) => el.question).reverse()
      : [];
  }

  private fetchFromQna(
    dbSearch: Awaited<ReturnType<typeof trpc.search.getSearch.query>>,
  ) {
    const question = dbSearch?.QuestionAnswer?.[0].question;
    const queryId = dbSearch?.queryId;
    if (!(question && queryId)) return;
    if (!dbSearch.name || dbSearch.name === question) {
      this.fetchTitle(question);
    }
    if (dbSearch.sources.length === 0) {
      this.fetchSources(question);
    }
    if (!dbSearch.QuestionAnswer?.[0].answer) {
      this.streamAnswer(question);
    }
    if (dbSearch.tags.length === 0) {
      this.fetchTags(question);
    }
  }

  public async fetchFromDb() {
    const queryId = this.getQueryId();
    const result = await trpc.search.getSearch.query(queryId);
    this.title.set(result?.name || '');
    this.answer.set(result?.QuestionAnswer?.[0].answer || '');
    this.tags.set(result?.tags.map((x) => x.text) || []);
    this.sources.set(
      result?.sources.map((x) => ({ title: x.title, url: x.url })) || [],
    );
    this.text.set(result?.QuestionAnswer?.[0].question || '');
    this.fetchFromQna(result);
  }

  public stopStreaming(): void {
    const abortAnswer = this.abort.get();
    if (abortAnswer) abortAnswer();
    this.abort.set(null);
  }

  public regenerate(): void {
    const question = this.safeGetText();
    this.streamAnswer(question);
  }
}

export function useQuestion(savedSearches: SavedSearches): Question {
  const { queryId } = useParams<{ queryId: string }>();
  const [user] = useAuth();
  return new Question(
    queryId,
    user,
    useRouter(),
    useStateItem(''), // text
    useStateItem(''), // title
    useStateItem(''), // answer
    useStateItem([]), // sources
    useStateItem(new Set()), // deletedSources
    useStateItem([]), // tags
    useStateItem(false), // isStreaming
    useRefItem(null), // abort
    savedSearches,
  );
}
